/*
 * @Descripttion:
 * @version:
 * @Author: ZhangChao
 * @Date: 2022-06-24 14:50:20
 * @LastEditors: ZhangChao
 * @LastEditTime: 2022-06-27 12:53:30
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 导入全局样式表
import './assets/css/global.css'
// 挂在所有过滤器
import * as filter from './assets/js/filter'
import * as zhongan from './assets/js/zhongan'
Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key])
})

Object.keys(zhongan).forEach(key => {
  Vue.filter('za_' + key, zhongan[key])
})

Vue.config.productionTip = false
const axioswlj = axios.create({
  baseURL: process.env.VUE_APP_WLJ_APIURL,
  withCredentials: true
})
axioswlj.interceptors.request.use(config => {
  // 添加请求令牌
  config.headers.Authorization = 'Bearer ' + localStorage.getItem('wljlogin_token')
  NProgress.start()
  return config
})
axioswlj.interceptors.response.use(async ret => {
  NProgress.done()
  if (ret.data) {
    if (ret.data.Code === -3) {
      // Vue.prototype.$message.error('跳转到202.102.3.141')
      location.replace('http://202.102.3.141')
    } else if (ret.data.Code === -1) {
      if (v.$route.meta.requireAuth !== false) {
        v.$router.push('/login')
      }
    } else if (ret.data.Code === 1 || ret.data.Code === 999999) {
      v.$message.error(ret.data.Msg)
    }
  }
  return ret
}, err => {
  NProgress.done()
  if (err.response && err.response.status) {
    if (err.response.status === 504) {
      v.$message.error('网关超时')
    } else if (err.response.status === 404) {
      v.$message.error('数据接口不存在')
    } else if (err.response.status === 403) {
      v.$message.error('权限不足,请联系管理员!')
    } else if (err.response.status === 413) {
      v.$message.error('文件过大超过限制!')
    } else {
      v.$message.error('服务器异常!')
    }
  } else {
    v.$message.error('服务器异常!')
  }
  return Promise.reject(err)
})

Vue.prototype.$http = { wlj: axioswlj }
Vue.prototype.CryptoJS = CryptoJS

const originalPush = router.push
router.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const v = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

v.$options.filters.setvue(v)
v.$options.filters.za_setvue(v)
