import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import axios from 'axios'
const cheerio = require('cheerio')

let fvue = ''
export function dateformat (v, fmt) {
  const o = {
    'M+': v.getMonth() + 1, // 月份
    'd+': v.getDate(), // 日
    'h+': v.getHours() % 12 === 0 ? 12 : v.getHours() % 12, // 小时
    'H+': v.getHours(), // 小时
    'm+': v.getMinutes(), // 分
    's+': v.getSeconds(), // 秒
    'q+': Math.floor((v.getMonth() + 3) / 3), // 季度
    S: v.getMilliseconds() // 毫秒
  }
  const week = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六'
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (v.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (RegExp.$1.length > 1
        ? RegExp.$1.length > 2
          ? '星期'
          : '周'
        : '') + week[v.getDay() + '']
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}

export function datestrformat (v, fmt, def) {
  if (!v || (v + '').trim() === '') {
    return def
  }
  try {
    return dateformat(Str2Date(v), fmt)
  } catch {
    return def
  }
}
export function Str2Date (obj) {
  const s = obj.replace(/-/gi, '/')
  const s1 = s.substr(0, 19)
  let s2 = ''
  let t = new Date()
  if (s.length > 19) {
    t = new Date(s1)
    s2 = s.substr(20, 3)
    if (s2 !== '' && s2 > 0) { t.setMilliseconds(s2) }
  } else {
    t = new Date(s1)
  }
  return t
}

export function wait (t) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, t)
  })
}

export function addnote (v, n) {
  let r = ''
  if (n && n.trim() !== '') {
    r = `(${n})`
  }
  return v + r
}
export function addnote2 (v, n) {
  let r = ''
  if (n && n.trim() !== '') {
    r = `[${n}]`
  }
  return v + r
}

export function setvue (v) {
  fvue = v
}
export function deepClone (data) {
  const _data = JSON.stringify(data)
  const dataClone = JSON.parse(_data)
  return dataClone
}

export function loginout () {
  localStorage.clear()
  location.reload()
}

/** 上传相关 */
export async function getsha256 (file, callback) {
  return new Promise((resolve, reject) => {
    let contractFile = file
    let reader = new FileReader()
    let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice
    const chunkSize = 6 * 1024 * 1024
    const chunks = Math.ceil(contractFile.size / chunkSize)
    let currentChunk = 0
    let hasher = fvue.CryptoJS.algo.SHA256.create()
    const start = currentChunk * chunkSize
    const end = start + chunkSize >= contractFile.size ? contractFile.size : start + chunkSize
    let blocks = []
    let block = blobSlice.call(contractFile, start, end)
    blocks.push(block)
    reader.readAsArrayBuffer(block)
    reader.onload = function (evt) {
      let fileStr = evt.target.result
      let tmpWordArray = arrayBufferToWordArray(fileStr)
      hasher.update(tmpWordArray)
      currentChunk += 1
      fileStr = null
      tmpWordArray = null
      if (callback) {
        const d = {
          blocks: blocks,
          blocksize: chunkSize,
          blocktotal: chunks,
          curblock: currentChunk
        }
        callback(d)
      }
      // console.log(currentChunk, chunks)
      if (currentChunk < chunks) {
        const start = currentChunk * chunkSize
        const end = start + chunkSize >= contractFile.size ? contractFile.size : start + chunkSize
        block = blobSlice.call(contractFile, start, end)
        blocks.push(block)
        reader.readAsArrayBuffer(block)
      }
    }
    reader.onloadend = function () {
      if (currentChunk >= chunks) {
        contractFile = null
        let hash = hasher.finalize()
        hasher = null
        blobSlice = null
        reader = null
        block = null
        resolve({
          blocks: blocks,
          hash: hash.toString(), // 计算结果
          blocksize: chunkSize
        })
        blocks = []
        hash = null
      }
    }
  })
}

function arrayBufferToWordArray (ab) {
  const i8a = new Uint8Array(ab)
  const a = []
  for (let i = 0; i < i8a.length; i += 4) {
    a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3])
  }
  return fvue.CryptoJS.lib.WordArray.create(a, i8a.length)
}

export async function getresreviewurl (id, filename, fileext, filesize, acttype, fileurl) {
  const fileType = checkSupportPreview(fileext)
  if (!fileType) {
    return false
  }
  let url = false
  if (['Txt', 'Image'].indexOf(fileType) >= 0) {
    url = await getopenPreview(id, fileext, filename, acttype, fileurl)
  } else {
    url = await getopenDocPreview(id, fileext, filename, acttype, fileurl)
  }
  return url
}

async function getopenPreview (id, fileext, filename, acttype, url) {
  return false
}

async function getopenDocPreview (id, fileext, filename, acttype, url) {
  const pathname = getPreviewUrl(fileext)
  if (!pathname) {
    return false
  }
  return `${process.env.VUE_APP_QUZYK_Office2}/op/view.aspx?src=${encodeURIComponent(url)}`
  // var { data } = await fvue.$http.quzyk.post('/api/v1/uc/user/gettoken', { id: id })
  // if (data && data.Code === 0) {
  //     const token = data.Data.token
  //     var WOPISrc = encodeURIComponent(
  //         `${process.env.VUE_APP_QUZYK_APIURL}/api/v1/wopi/files/${id}/review_${acttype}`
  //     )

  //     return `${process.env.VUE_APP_QUZYK_Office}${pathname}WOPISrc=${WOPISrc}&access_token=${token}&ui=zh-CN`
  // } else if (data.Code !== 1) {
  //     fvue.$message.warning(data.Msg)
  //     return false
  // } else { return false }
}

/** 文件预览 */
export async function resreview (id, filename, fileext, filesize, acttype, url) {
  const fileType = checkSupportPreview(fileext)
  if (!fileType) {
    warning2('文件不支持预览')
    return
  }
  switch (fileType) {
    case 'Word':
    case 'PowerPoint':
    case 'WordPdf':
      // 限制10M
      // if (filesize >= 10 * 1024 * 1024) {
      //     warning2('文件超过10M,不支持在线预览,请下载后打开')
      //     return
      // }
      break
    case 'Excel':
      // 限制5M
      // if (filesize >= 5 * 1024 * 1024) {
      //     warning2('文件超过5M,不支持在线预览,请下载后打开')
      //     return
      // }
      break
  }
  if (['Txt', 'Image'].indexOf(fileType) >= 0) {
    await openPreview(id, fileext, filename, acttype, url)
  } else {
    await openDocPreview(id, fileext, filename, acttype)
  }
}

async function openPreview (id, fileext, filename, acttype, url) {
  warning2('文件不支持预览')
}

async function openDocPreview (id, fileext, filename, acttype, url) {
  const pathname = getPreviewUrl(fileext)
  if (!pathname) {
    fvue.$message.warning(`不支持预览[${fileext}]文件`)
    return
  }
  window.open(`${process.env.VUE_APP_QUZYK_Office2}/op/view.aspx?src=${encodeURIComponent(url)}`)
}

function getPreviewUrl (fileext) {
  const docName = checkSupportPreview(fileext)
  if (!docName) return false
  return fvue.$store.state.site.docToUrl[docName]
}
export function checkSupportPreview (fileext) {
  for (const key in fvue.$store.state.site.docToExt) {
    if (fvue.$store.state.site.docToExt[key].indexOf(fileext) >= 0) {
      return key
    }
  }
  return false
}

export function warning (title) {
  const message = fvue.$message.warning({
    message: `${title}`,
    duration: 0,
    customClass: 'el-message--warning',
    iconClass: 'el-icon-loading'
  })
  message.Close = function (msg, delaytime) {
    if (typeof msg === 'undefined') {
      msg = '操作成功'
    }
    if (typeof delaytime === 'undefined') {
      delaytime = 500
    }
    message.message = msg
    message.iconClass = 'el-icon-check'
    setTimeout(() => {
      message.close()
    }, delaytime)
  }
  return message
}
export function warning2 (title, callback, time) {
  const message = fvue.$message.warning({
    message: `${title}`,
    duration: time || 3000,
    onClose: () => {
      if (this.IsFunction(callback)) {
        callback()
      }
    }
  })
  return message
}

//
export function encrypt (val) {
  const key = fvue.CryptoJS.enc.Latin1.parse('2022-wlj+ijn=df-')
  const iv = fvue.CryptoJS.enc.Latin1.parse('wlj=202206251458')
  return fvue.CryptoJS.AES.encrypt(val, key,
    {
      iv: iv,
      mode: fvue.CryptoJS.mode.CBC,
      padding: fvue.CryptoJS.pad.Pkcs7
    }).toString()
}

export function filesize (val) {
  if (isNaN(val)) return 0
  if (val > 1024) return val / 1024 + 'g'
  return val + 'mb'
}

// 通用递归方法 用于tree数据组合 主方法dg
// list: 待分析的list  topid：顶级
// 规则: id/pid/label
export function dg (list, topid) {
  const result = []
  let tmp = list.filter(p => { return topid === p.pid })
  if (tmp.length === 0) tmp = list
  tmp.forEach(p => {
    // var tmpnode = {}
    // tmpnode.id = p.id
    // tmpnode.label = p.label
    const tmpchildren = dg2(p, list)
    if (tmpchildren.length > 0) p.children = tmpchildren
    result.push(p)
  })
  return result
}

export function dg2 (item, list) {
  const result = []
  const tmp = list.filter(p => { return p.pid === item.id })
  tmp.forEach(p => {
    // var tmpnode = {}
    // tmpnode.id = p.id
    // tmpnode.label = p.label
    const tmpchildren = dg2(p, list)
    if (tmpchildren.length > 0) [p].children = tmpchildren
    result.push(p)
  })
  return result
}

export function exportExcel (title, obj) {
  /* 从表生成工作簿对象 */
  let fix = document.querySelector(obj).querySelector('.el-table__fixed')
  if (!fix) fix = document.querySelector(obj).querySelector('.el-table__fixed-right')
  const xlsxParam = { raw: true } // 转换成excel时，使用原始的格式
  let wb
  if (fix) {
    try {
      wb = XLSX.utils.table_to_book(document.querySelector(obj).removeChild(fix), xlsxParam)
      document.querySelector(obj).appendChild(fix)
    } catch (error) {
      wb = XLSX.utils.table_to_book(document.querySelector(obj), xlsxParam)
    }
  } else {
    wb = XLSX.utils.table_to_book(document.querySelector(obj), xlsxParam)
  }
  /* 获取二进制字符串作为输出 */
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array'
  })
  try {
    // Blob 对象表示一个不可变、原始数据的类文件对象。
    // Blob 表示的不一定是JavaScript原生格式的数据。
    // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
    // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
    FileSaver.saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      // 设置导出文件名称
      title + '.xlsx')
  } catch (e) {
    if (typeof console !== 'undefined') console.log(e, wbout)
  } return wbout
}
export function getTableJson (obj) {
  /* 从表生成工作簿对象 */
  let fix = document.querySelector(obj).querySelector('.el-table__fixed')
  if (!fix) fix = document.querySelector(obj).querySelector('.el-table__fixed-right')
  const xlsxParam = { raw: true } // 转换成excel时，使用原始的格式
  let wb
  if (fix) {
    try {
      wb = XLSX.utils.table_to_sheet(document.querySelector(obj).removeChild(fix), xlsxParam)
      document.querySelector(obj).appendChild(fix)
    } catch (error) {
      wb = XLSX.utils.table_to_sheet(document.querySelector(obj), xlsxParam)
    }
  } else {
    wb = XLSX.utils.table_to_sheet(document.querySelector(obj), xlsxParam)
  }
  return XLSX.utils.sheet_to_json(wb)
}
export function exportJsonToExcel (title, json, sheetname) {
  function workbook2blob (workbook) {
    // 生成excel的配置项
    const wopts = {
      // 要生成的文件类型
      bookType: 'xlsx',
      // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      bookSST: true,
      type: 'binary'
    }
    const wbout = XLSX.write(workbook, wopts)
    // 将字符串转ArrayBuffer
    function s2ab (s) {
      const buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
      return buf
    }
    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream'
    })
    return blob
  }
  const wb = XLSX.utils.book_new()
  const sheet1 = XLSX.utils.json_to_sheet(json)
  // var sheet2 = XLSX.utils.json_to_sheet(sheet2data);
  // var sheet3 = XLSX.utils.json_to_sheet(sheet3data);

  XLSX.utils.book_append_sheet(wb, sheet1, sheetname)
  // XLSX.utils.book_append_sheet(wb, sheet2, "行政部");
  // XLSX.utils.book_append_sheet(wb, sheet3, "前端部");

  const wbout = workbook2blob(wb)

  try {
    // Blob 对象表示一个不可变、原始数据的类文件对象。
    // Blob 表示的不一定是JavaScript原生格式的数据。
    // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
    // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
    FileSaver.saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      // 设置导出文件名称
      title + '.xlsx')
  } catch (e) {
    if (typeof console !== 'undefined') console.log(e, wbout)
  }
  return wbout
}

// 小文件下载，由于跨域download属性无效，不填filesize永久使用axios下载，不会跳播放
// 仅用于下载链接中不含文件名头的情况，若含文件名头，直接使用download即可
export function downloadwithaxios (url, filename, filesize) {
  if (filesize > 20 * 1024 * 1024) {
    download(url, filename)
  } else {
    axios.get(url, { responseType: 'blob' }).then(response => {
      const blob = new Blob([response.data])
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      if (filename) link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    }).catch(console.error)
  }
}

// 大文件下载，正常通过a标签打开新页面，视频会直接在浏览器播放
export function download (url, filename) {
  const a = document.createElement('a')
  document.body.append(a)
  a.style.display = 'none'
  a.href = url
  if (filename) a.download = filename
  a.click()
  setTimeout(() => {
    document.body.removeChild(a)
  }, 300)
}
export function getHtmlText (htmlString) {
  const $ = cheerio.load(htmlString)
  return $('body').text()
}
