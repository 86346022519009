/*
 * @Descripttion:
 * @version:
 * @Author: ZhangChao
 * @Date: 2022-06-24 14:50:20
 * @LastEditors: ZhangChao
 * @LastEditTime: 2023-03-06 20:09:30
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: { main: true, requireAuth: false }
  },
  {
    path: '/login/:route',
    meta: { requireAuth: false },
    component: () => import(/* webpackChunkName: "home" */ '../views/login/index.vue')
  },
  {
    path: '/login',
    meta: { requireAuth: false },
    component: () => import(/* webpackChunkName: "home" */ '../views/login/index.vue')
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/main.vue'),
    redirect: '/home/index',
    children: [{
      path: '/home/index',
      component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
      // redirect: '/yunkt/myres'
    }]
  },
  {
    path: '/fxzl',
    component: () => import(/* webpackChunkName: "fxzl" */ '../views/home/main.vue'),
    redirect: '/fxzl/gjzw',
    children: [{
      path: '/fxzl/gjzw',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/gjzw.vue')
    }, {
      path: '/fxzl/sbss',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/sbss.vue')
    }, {
      path: '/fxzl/tzsb',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/tzsb.vue')
    }, {
      path: '/fxzl/hxp',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/hxp.vue')
    }, {
      path: '/fxzl/yxkj',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/yxkj.vue')
    }, {
      path: '/fxzl/yhsjk',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/yhsjk.vue')
    }, {
      path: '/fxzl/fxbs/:unitId/:unitType',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/fxbs.vue')
    }, {
      path: '/fxzl/flfg',
      component: () => import(/* webpackChunkName: "fxzl" */ '../views/fxzl/flfg.vue')
    }]
  },
  {
    path: '/yhpc',
    component: () => import(/* webpackChunkName: "yhpc" */ '../views/home/main.vue'),
    redirect: '/yhpc/xmlist',
    children: [{
      path: '/yhpc/xmlist',
      component: () => import(/* webpackChunkName: "yhpc" */ '../views/yhpc/xmlist.vue')
    }, {
      path: '/yhpc/loglist',
      component: () => import(/* webpackChunkName: "yhpc" */ '../views/yhpc/loglist.vue')
    }]
  },
  {
    path: '/dxp',
    component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/main.vue'),
    redirect: '/dxp/index',
    children: [{
      path: '/dxp/index',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/index.vue')
    }, {
      path: '/dxp/p1',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p1.vue')
    }, {
      path: '/dxp/p2',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p2.vue')
    }, {
      path: '/dxp/p3',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p3.vue')
    }, {
      path: '/dxp/p5',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p5.vue')
    }, {
      path: '/dxp/p6',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p6.vue')
    }, {
      path: '/dxp/p7',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p7.vue')
    }, {
      path: '/dxp/p8',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p8.vue')
    }, {
      path: '/dxp/p9',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p9.vue')
    }, {
      path: '/dxp/p10',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p10.vue')
    }, {
      path: '/dxp/p11',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p11.vue')
    }, {
      path: '/dxp/p12',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p12.vue')
    }, {
      path: '/dxp/p13',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p13.vue')
    }, {
      path: '/dxp/p14',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p14.vue')
    }, {
      path: '/dxp/p15',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p15.vue')
    }, {
      path: '/dxp/p16',
      component: () => import(/* webpackChunkName: "dxp" */ '../views/dxp/p16.vue')
    },
    {
      path: '/dxp/*',
      redirect: '/dxp/index'
    }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
