/*
 * @Descripttion:
 * @version:
 * @Author: ZhangChao
 * @Date: 2022-06-24 14:50:20
 * @LastEditors: ZhangChao
 * @LastEditTime: 2023-04-08 15:48:35
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dylx: [{
      id: 1,
      name: '生产'
    }, {
      id: 2,
      name: '存储'
    }, {
      id: 3,
      name: '其他'
    }],
    riskLevel: [{
      level: 1,
      levelName: '低风险'
    }, {
      level: 2,
      levelName: '一般风险'
    }, {
      level: 3,
      levelName: '较大风险'
    }, {
      level: 4,
      levelName: '重大风险'
    }],
    typecategory: [{
      type: 1,
      typeName: '锅炉',
      children: [{
        classes: 1,
        classesName: '承压蒸汽锅炉'
      }, {
        classes: 2,
        classesName: '承压热水锅炉'
      }, {
        classes: 3,
        classesName: '有热载体锅炉'
      }]
    }, {
      type: 2,
      typeName: '压力容器',
      children: [{
        classes: 1,
        classesName: '固定式压力容器'
      }, {
        classes: 2,
        classesName: '移动式压力容器'
      }, {
        classes: 3,
        classesName: '气瓶'
      }, {
        classes: 4,
        classesName: '氧舱'
      }]
    }, {
      type: 3,
      typeName: '压力管道',
      children: [{
        classes: 1,
        classesName: '长输管道'
      }, {
        classes: 2,
        classesName: '公用管道'
      }, {
        classes: 3,
        classesName: '工业管道'
      }]
    }, {
      type: 9,
      typeName: '压力管道元件',
      children: [{
        classes: 1,
        classesName: '压力管道管子'
      }, {
        classes: 2,
        classesName: '压力管道管件'
      }, {
        classes: 3,
        classesName: '压力管道阀门'
      }, {
        classes: 4,
        classesName: '压力管道法兰'
      }, {
        classes: 5,
        classesName: '补偿器'
      }, {
        classes: 6,
        classesName: '压力管道密封元件'
      }, {
        classes: 7,
        classesName: '压力管道特种元件'
      }]
    }, {
      type: 10,
      typeName: '电梯',
      children: [{
        classes: 1,
        classesName: '曳引与强制驱动电梯'
      }, {
        classes: 2,
        classesName: '液压驱动电梯'
      }, {
        classes: 3,
        classesName: '自动扶梯与自动人行道'
      }, {
        classes: 4,
        classesName: '其它类型电梯'
      }]
    }, {
      type: 4,
      typeName: '起重机械',
      children: [{
        classes: 1,
        classesName: '桥式起重机'
      }, {
        classes: 2,
        classesName: '门式起重机'
      }, {
        classes: 3,
        classesName: '塔式起重机'
      }, {
        classes: 4,
        classesName: '流动式起重机'
      }, {
        classes: 5,
        classesName: '门座式起重机'
      }, {
        classes: 6,
        classesName: '升降机'
      }, {
        classes: 7,
        classesName: '缆索式起重机'
      }, {
        classes: 8,
        classesName: '桅杆式起重机'
      }, {
        classes: 9,
        classesName: '机械式停车设备'
      }]
    }, {
      type: 5,
      typeName: '客运索道',
      children: [{
        classes: 1,
        classesName: '客运架空索道'
      }, {
        classes: 2,
        classesName: '客运缆车'
      }, {
        classes: 3,
        classesName: '客运拖牵索道'
      }]
    }, {
      type: 6,
      typeName: '大型游乐设施',
      children: [{
        classes: 1,
        classesName: '观览车类'
      }, {
        classes: 2,
        classesName: '滑行车类'
      }, {
        classes: 3,
        classesName: '架空游览车类'
      }, {
        classes: 4,
        classesName: '陀螺类'
      }, {
        classes: 5,
        classesName: '飞行塔类'
      }, {
        classes: 6,
        classesName: '转马类'
      }, {
        classes: 7,
        classesName: '自控飞机类'
      }, {
        classes: 8,
        classesName: '赛车类'
      }, {
        classes: 9,
        classesName: '小火车类'
      }, {
        classes: 10,
        classesName: '碰碰车类'
      }, {
        classes: 11,
        classesName: '滑道类'
      }, {
        classes: 12,
        classesName: '水上游乐设施'
      }, {
        classes: 13,
        classesName: '无动力游乐设施'
      }]
    }, {
      type: 7,
      typeName: '场内机动车',
      children: [{
        classes: 1,
        classesName: '机动工业车辆'
      }, {
        classes: 2,
        classesName: '非公路用旅游观光车辆'
      }]
    }, {
      type: 8,
      typeName: '安全附件',
      children: [{
        classes: 1,
        classesName: '安全阀'
      }, {
        classes: 2,
        classesName: '爆破片装置'
      }, {
        classes: 3,
        classesName: '紧急切断阀'
      }, {
        classes: 4,
        classesName: '气瓶阀门'
      }]
    }],
    chemistryList: [{
      type: 1,
      typeName: '爆炸物'
    }, {
      type: 2,
      typeName: '易燃气体'
    }, {
      type: 3,
      typeName: '气溶胶'
    }, {
      type: 4,
      typeName: '氧化性气体'
    }, {
      type: 5,
      typeName: '易燃液体'
    }, {
      type: 6,
      typeName: '易燃固体'
    }, {
      type: 7,
      typeName: '自反应物质和混合物'
    }, {
      type: 8,
      typeName: '自燃液体'
    }, {
      type: 9,
      typeName: '自燃固体'
    }, {
      type: 10,
      typeName: '自热物质和混合物'
    }, {
      type: 11,
      typeName: '遇水放出自燃气体的物质和混合物'
    }, {
      type: 12,
      typeName: '氧化性液体'
    }, {
      type: 13,
      typeName: '氧化性固体'
    }, {
      type: 14,
      typeName: '有机过氧化物'
    }, {
      type: 15,
      typeName: '急性毒性'
    }, {
      type: 16,
      typeName: '一元无机强酸'
    }, {
      type: 17,
      typeName: '压缩气体'
    }, {
      type: 18,
      typeName: '液化气体'
    }, {
      type: 19,
      typeName: '腐蚀品'
    }],
    spaceType: [{
      type: 1,
      typeName: '地下有限空间'
    }, {
      type: 2,
      typeName: '地上有限空间'
    }, {
      type: 3,
      typeName: '密闭设备'
    }, {
      type: 4,
      typeName: '冶金企业非标设备'
    }],
    yhType: [{
      type: 1,
      typeName: '一般事故隐患'
    }, {
      type: 2,
      typeName: '重大事故隐患'
    }],
    yhdl: [{
      type: 1,
      typeName: '基础管理'
    }, {
      type: 2,
      typeName: '现场管理'
    }],
    flfgtype: [{
      id: 1,
      value: '法律'
    }, {
      id: 2,
      value: '规定'
    }, {
      id: 3,
      value: '条例'
    }, {
      id: 4,
      value: '办法'
    }, {
      id: 5,
      value: '标准'
    }, {
      id: 6,
      value: '地方'
    }],
    hangye: ['轻工行业', '机械行业', '冶金行业', '有色行业', '建材行业', '纺织行业', '烟草行业', '商贸行业']
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
