<!--
 * @Descripttion:
 * @version:
 * @Author: ZhangChao
 * @Date: 2022-06-24 14:50:20
 * @LastEditors: ZhangChao
 * @LastEditTime: 2022-06-25 17:14:02
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'app',
    components: {}
  }
</script>

<style>
</style>
