/*
 * @Descripttion:
 * @version:
 * @Author: ZhangChao
 * @Date: 2022-06-24 15:04:28
 * @LastEditors: ZhangChao
 * @LastEditTime: 2022-06-25 17:22:47
 */
import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Element, { size: 'small', zIndex: 3000 })
Vue.prototype.$message = Element.Message
Vue.prototype.$confirm = Element.MessageBox.confirm
Vue.prototype.$prompt = Element.MessageBox.prompt
Vue.prototype.$alert = Element.MessageBox.alert
