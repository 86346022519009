/* eslint-disable */
let at = null
var et = 'undefined' != typeof globalThis ? globalThis : 'undefined' != typeof window ? window : 'undefined' != typeof global ? global : 'undefined' != typeof self ? self : {}
var Ro = {}
!(function (e, t) {
  ; (function () {
    var t
    function i (e, t, i) {
      null != e && ('number' == typeof e ? this.fromNumber(e, t, i) : null == t && 'string' != typeof e ? this.fromString(e, 256) : this.fromString(e, t))
    }
    function n () {
      return new i(null)
    }
    var o = 'undefined' != typeof navigator
    o && 'Microsoft Internet Explorer' == navigator.appName
      ? ((i.prototype.am = function (e, t, i, n, o, a) {
        for (var s = 32767 & t, r = t >> 15; --a >= 0;) {
          var l = 32767 & this[e],
            c = this[e++] >> 15,
            d = r * l + c * s
            ; (o = ((l = s * l + ((32767 & d) << 15) + i[n] + (1073741823 & o)) >>> 30) + (d >>> 15) + r * c + (o >>> 30)), (i[n++] = 1073741823 & l)
        }
        return o
      }),
        (t = 30))
      : o && 'Netscape' != navigator.appName
        ? ((i.prototype.am = function (e, t, i, n, o, a) {
          for (; --a >= 0;) {
            var s = t * this[e++] + i[n] + o
              ; (o = Math.floor(s / 67108864)), (i[n++] = 67108863 & s)
          }
          return o
        }),
          (t = 26))
        : ((i.prototype.am = function (e, t, i, n, o, a) {
          for (var s = 16383 & t, r = t >> 14; --a >= 0;) {
            var l = 16383 & this[e],
              c = this[e++] >> 14,
              d = r * l + c * s
              ; (o = ((l = s * l + ((16383 & d) << 14) + i[n] + o) >> 28) + (d >> 14) + r * c), (i[n++] = 268435455 & l)
          }
          return o
        }),
          (t = 28)),
      (i.prototype.DB = t),
      (i.prototype.DM = (1 << t) - 1),
      (i.prototype.DV = 1 << t)
      ; (i.prototype.FV = Math.pow(2, 52)), (i.prototype.F1 = 52 - t), (i.prototype.F2 = 2 * t - 52)
    var a,
      s,
      r = new Array()
    for (a = '0'.charCodeAt(0), s = 0; s <= 9; ++s) r[a++] = s
    for (a = 'a'.charCodeAt(0), s = 10; s < 36; ++s) r[a++] = s
    for (a = 'A'.charCodeAt(0), s = 10; s < 36; ++s) r[a++] = s
    function l (e) {
      return '0123456789abcdefghijklmnopqrstuvwxyz'.charAt(e)
    }
    function c (e, t) {
      var i = r[e.charCodeAt(t)]
      return null == i ? -1 : i
    }
    function d (e) {
      var t = n()
      return t.fromInt(e), t
    }
    function u (e) {
      var t,
        i = 1
      return 0 != (t = e >>> 16) && ((e = t), (i += 16)), 0 != (t = e >> 8) && ((e = t), (i += 8)), 0 != (t = e >> 4) && ((e = t), (i += 4)), 0 != (t = e >> 2) && ((e = t), (i += 2)), 0 != (t = e >> 1) && ((e = t), (i += 1)), i
    }
    function h (e) {
      this.m = e
    }
    function p (e) {
      ; (this.m = e), (this.mp = e.invDigit()), (this.mpl = 32767 & this.mp), (this.mph = this.mp >> 15), (this.um = (1 << (e.DB - 15)) - 1), (this.mt2 = 2 * e.t)
    }
    function m (e, t) {
      return e & t
    }
    function g (e, t) {
      return e | t
    }
    function f (e, t) {
      return e ^ t
    }
    function y (e, t) {
      return e & ~t
    }
    function b (e) {
      if (0 == e) return -1
      var t = 0
      return 0 == (65535 & e) && ((e >>= 16), (t += 16)), 0 == (255 & e) && ((e >>= 8), (t += 8)), 0 == (15 & e) && ((e >>= 4), (t += 4)), 0 == (3 & e) && ((e >>= 2), (t += 2)), 0 == (1 & e) && ++t, t
    }
    function v (e) {
      for (var t = 0; 0 != e;) (e &= e - 1), ++t
      return t
    }
    function w () { }
    function _ (e) {
      return e
    }
    function k (e) {
      ; (this.r2 = n()), (this.q3 = n()), i.ONE.dlShiftTo(2 * e.t, this.r2), (this.mu = this.r2.divide(e)), (this.m = e)
    }
    ; (h.prototype.convert = function (e) {
      return e.s < 0 || e.compareTo(this.m) >= 0 ? e.mod(this.m) : e
    }),
      (h.prototype.revert = function (e) {
        return e
      }),
      (h.prototype.reduce = function (e) {
        e.divRemTo(this.m, null, e)
      }),
      (h.prototype.mulTo = function (e, t, i) {
        e.multiplyTo(t, i), this.reduce(i)
      }),
      (h.prototype.sqrTo = function (e, t) {
        e.squareTo(t), this.reduce(t)
      }),
      (p.prototype.convert = function (e) {
        var t = n()
        return e.abs().dlShiftTo(this.m.t, t), t.divRemTo(this.m, null, t), e.s < 0 && t.compareTo(i.ZERO) > 0 && this.m.subTo(t, t), t
      }),
      (p.prototype.revert = function (e) {
        var t = n()
        return e.copyTo(t), this.reduce(t), t
      }),
      (p.prototype.reduce = function (e) {
        for (; e.t <= this.mt2;) e[e.t++] = 0
        for (var t = 0; t < this.m.t; ++t) {
          var i = 32767 & e[t],
            n = (i * this.mpl + (((i * this.mph + (e[t] >> 15) * this.mpl) & this.um) << 15)) & e.DM
          for (e[(i = t + this.m.t)] += this.m.am(0, n, e, t, 0, this.m.t); e[i] >= e.DV;) (e[i] -= e.DV), e[++i]++
        }
        e.clamp(), e.drShiftTo(this.m.t, e), e.compareTo(this.m) >= 0 && e.subTo(this.m, e)
      }),
      (p.prototype.mulTo = function (e, t, i) {
        e.multiplyTo(t, i), this.reduce(i)
      }),
      (p.prototype.sqrTo = function (e, t) {
        e.squareTo(t), this.reduce(t)
      }),
      (i.prototype.copyTo = function (e) {
        for (var t = this.t - 1; t >= 0; --t) e[t] = this[t]
          ; (e.t = this.t), (e.s = this.s)
      }),
      (i.prototype.fromInt = function (e) {
        ; (this.t = 1), (this.s = e < 0 ? -1 : 0), e > 0 ? (this[0] = e) : e < -1 ? (this[0] = e + this.DV) : (this.t = 0)
      }),
      (i.prototype.fromString = function (e, t) {
        var n
        if (16 == t) n = 4
        else if (8 == t) n = 3
        else if (256 == t) n = 8
        else if (2 == t) n = 1
        else if (32 == t) n = 5
        else {
          if (4 != t) return void this.fromRadix(e, t)
          n = 2
        }
        ; (this.t = 0), (this.s = 0)
        for (var o = e.length, a = !1, s = 0; --o >= 0;) {
          var r = 8 == n ? 255 & e[o] : c(e, o)
          r < 0 ? '-' == e.charAt(o) && (a = !0) : ((a = !1), 0 == s ? (this[this.t++] = r) : s + n > this.DB ? ((this[this.t - 1] |= (r & ((1 << (this.DB - s)) - 1)) << s), (this[this.t++] = r >> (this.DB - s))) : (this[this.t - 1] |= r << s), (s += n) >= this.DB && (s -= this.DB))
        }
        8 == n && 0 != (128 & e[0]) && ((this.s = -1), s > 0 && (this[this.t - 1] |= ((1 << (this.DB - s)) - 1) << s)), this.clamp(), a && i.ZERO.subTo(this, this)
      }),
      (i.prototype.clamp = function () {
        for (var e = this.s & this.DM; this.t > 0 && this[this.t - 1] == e;) --this.t
      }),
      (i.prototype.dlShiftTo = function (e, t) {
        var i
        for (i = this.t - 1; i >= 0; --i) t[i + e] = this[i]
        for (i = e - 1; i >= 0; --i) t[i] = 0
          ; (t.t = this.t + e), (t.s = this.s)
      }),
      (i.prototype.drShiftTo = function (e, t) {
        for (var i = e; i < this.t; ++i) t[i - e] = this[i]
          ; (t.t = Math.max(this.t - e, 0)), (t.s = this.s)
      }),
      (i.prototype.lShiftTo = function (e, t) {
        var i,
          n = e % this.DB,
          o = this.DB - n,
          a = (1 << o) - 1,
          s = Math.floor(e / this.DB),
          r = (this.s << n) & this.DM
        for (i = this.t - 1; i >= 0; --i) (t[i + s + 1] = (this[i] >> o) | r), (r = (this[i] & a) << n)
        for (i = s - 1; i >= 0; --i) t[i] = 0
          ; (t[s] = r), (t.t = this.t + s + 1), (t.s = this.s), t.clamp()
      }),
      (i.prototype.rShiftTo = function (e, t) {
        t.s = this.s
        var i = Math.floor(e / this.DB)
        if (i >= this.t) t.t = 0
        else {
          var n = e % this.DB,
            o = this.DB - n,
            a = (1 << n) - 1
          t[0] = this[i] >> n
          for (var s = i + 1; s < this.t; ++s) (t[s - i - 1] |= (this[s] & a) << o), (t[s - i] = this[s] >> n)
          n > 0 && (t[this.t - i - 1] |= (this.s & a) << o), (t.t = this.t - i), t.clamp()
        }
      }),
      (i.prototype.subTo = function (e, t) {
        for (var i = 0, n = 0, o = Math.min(e.t, this.t); i < o;) (n += this[i] - e[i]), (t[i++] = n & this.DM), (n >>= this.DB)
        if (e.t < this.t) {
          for (n -= e.s; i < this.t;) (n += this[i]), (t[i++] = n & this.DM), (n >>= this.DB)
          n += this.s
        } else {
          for (n += this.s; i < e.t;) (n -= e[i]), (t[i++] = n & this.DM), (n >>= this.DB)
          n -= e.s
        }
        ; (t.s = n < 0 ? -1 : 0), n < -1 ? (t[i++] = this.DV + n) : n > 0 && (t[i++] = n), (t.t = i), t.clamp()
      }),
      (i.prototype.multiplyTo = function (e, t) {
        var n = this.abs(),
          o = e.abs(),
          a = n.t
        for (t.t = a + o.t; --a >= 0;) t[a] = 0
        for (a = 0; a < o.t; ++a) t[a + n.t] = n.am(0, o[a], t, a, 0, n.t)
          ; (t.s = 0), t.clamp(), this.s != e.s && i.ZERO.subTo(t, t)
      }),
      (i.prototype.squareTo = function (e) {
        for (var t = this.abs(), i = (e.t = 2 * t.t); --i >= 0;) e[i] = 0
        for (i = 0; i < t.t - 1; ++i) {
          var n = t.am(i, t[i], e, 2 * i, 0, 1)
            ; (e[i + t.t] += t.am(i + 1, 2 * t[i], e, 2 * i + 1, n, t.t - i - 1)) >= t.DV && ((e[i + t.t] -= t.DV), (e[i + t.t + 1] = 1))
        }
        e.t > 0 && (e[e.t - 1] += t.am(i, t[i], e, 2 * i, 0, 1)), (e.s = 0), e.clamp()
      }),
      (i.prototype.divRemTo = function (e, t, o) {
        var a = e.abs()
        if (!(a.t <= 0)) {
          var s = this.abs()
          if (s.t < a.t) return null != t && t.fromInt(0), void (null != o && this.copyTo(o))
          null == o && (o = n())
          var r = n(),
            l = this.s,
            c = e.s,
            d = this.DB - u(a[a.t - 1])
          d > 0 ? (a.lShiftTo(d, r), s.lShiftTo(d, o)) : (a.copyTo(r), s.copyTo(o))
          var h = r.t,
            p = r[h - 1]
          if (0 != p) {
            var m = p * (1 << this.F1) + (h > 1 ? r[h - 2] >> this.F2 : 0),
              g = this.FV / m,
              f = (1 << this.F1) / m,
              y = 1 << this.F2,
              b = o.t,
              v = b - h,
              w = null == t ? n() : t
            for (r.dlShiftTo(v, w), o.compareTo(w) >= 0 && ((o[o.t++] = 1), o.subTo(w, o)), i.ONE.dlShiftTo(h, w), w.subTo(r, r); r.t < h;) r[r.t++] = 0
            for (; --v >= 0;) {
              var _ = o[--b] == p ? this.DM : Math.floor(o[b] * g + (o[b - 1] + y) * f)
              if ((o[b] += r.am(0, _, o, v, 0, h)) < _) for (r.dlShiftTo(v, w), o.subTo(w, o); o[b] < --_;) o.subTo(w, o)
            }
            null != t && (o.drShiftTo(h, t), l != c && i.ZERO.subTo(t, t)), (o.t = h), o.clamp(), d > 0 && o.rShiftTo(d, o), l < 0 && i.ZERO.subTo(o, o)
          }
        }
      }),
      (i.prototype.invDigit = function () {
        if (this.t < 1) return 0
        var e = this[0]
        if (0 == (1 & e)) return 0
        var t = 3 & e
        return (t = ((t = ((t = ((t = (t * (2 - (15 & e) * t)) & 15) * (2 - (255 & e) * t)) & 255) * (2 - (((65535 & e) * t) & 65535))) & 65535) * (2 - ((e * t) % this.DV))) % this.DV) > 0 ? this.DV - t : -t
      }),
      (i.prototype.isEven = function () {
        return 0 == (this.t > 0 ? 1 & this[0] : this.s)
      }),
      (i.prototype.exp = function (e, t) {
        if (e > 4294967295 || e < 1) return i.ONE
        var o = n(),
          a = n(),
          s = t.convert(this),
          r = u(e) - 1
        for (s.copyTo(o); --r >= 0;)
          if ((t.sqrTo(o, a), (e & (1 << r)) > 0)) t.mulTo(a, s, o)
          else {
            var l = o
              ; (o = a), (a = l)
          }
        return t.revert(o)
      }),
      (i.prototype.toString = function (e) {
        if (this.s < 0) return '-' + this.negate().toString(e)
        var t
        if (16 == e) t = 4
        else if (8 == e) t = 3
        else if (2 == e) t = 1
        else if (32 == e) t = 5
        else {
          if (4 != e) return this.toRadix(e)
          t = 2
        }
        var i,
          n = (1 << t) - 1,
          o = !1,
          a = '',
          s = this.t,
          r = this.DB - ((s * this.DB) % t)
        if (s-- > 0) for (r < this.DB && (i = this[s] >> r) > 0 && ((o = !0), (a = l(i))); s >= 0;) r < t ? ((i = (this[s] & ((1 << r) - 1)) << (t - r)), (i |= this[--s] >> (r += this.DB - t))) : ((i = (this[s] >> (r -= t)) & n), r <= 0 && ((r += this.DB), --s)), i > 0 && (o = !0), o && (a += l(i))
        return o ? a : '0'
      }),
      (i.prototype.negate = function () {
        var e = n()
        return i.ZERO.subTo(this, e), e
      }),
      (i.prototype.abs = function () {
        return this.s < 0 ? this.negate() : this
      }),
      (i.prototype.compareTo = function (e) {
        var t = this.s - e.s
        if (0 != t) return t
        var i = this.t
        if (0 != (t = i - e.t)) return this.s < 0 ? -t : t
        for (; --i >= 0;) if (0 != (t = this[i] - e[i])) return t
        return 0
      }),
      (i.prototype.bitLength = function () {
        return this.t <= 0 ? 0 : this.DB * (this.t - 1) + u(this[this.t - 1] ^ (this.s & this.DM))
      }),
      (i.prototype.mod = function (e) {
        var t = n()
        return this.abs().divRemTo(e, null, t), this.s < 0 && t.compareTo(i.ZERO) > 0 && e.subTo(t, t), t
      }),
      (i.prototype.modPowInt = function (e, t) {
        var i
        return (i = e < 256 || t.isEven() ? new h(t) : new p(t)), this.exp(e, i)
      }),
      (i.ZERO = d(0)),
      (i.ONE = d(1)),
      (w.prototype.convert = _),
      (w.prototype.revert = _),
      (w.prototype.mulTo = function (e, t, i) {
        e.multiplyTo(t, i)
      }),
      (w.prototype.sqrTo = function (e, t) {
        e.squareTo(t)
      }),
      (k.prototype.convert = function (e) {
        if (e.s < 0 || e.t > 2 * this.m.t) return e.mod(this.m)
        if (e.compareTo(this.m) < 0) return e
        var t = n()
        return e.copyTo(t), this.reduce(t), t
      }),
      (k.prototype.revert = function (e) {
        return e
      }),
      (k.prototype.reduce = function (e) {
        for (e.drShiftTo(this.m.t - 1, this.r2), e.t > this.m.t + 1 && ((e.t = this.m.t + 1), e.clamp()), this.mu.multiplyUpperTo(this.r2, this.m.t + 1, this.q3), this.m.multiplyLowerTo(this.q3, this.m.t + 1, this.r2); e.compareTo(this.r2) < 0;) e.dAddOffset(1, this.m.t + 1)
        for (e.subTo(this.r2, e); e.compareTo(this.m) >= 0;) e.subTo(this.m, e)
      }),
      (k.prototype.mulTo = function (e, t, i) {
        e.multiplyTo(t, i), this.reduce(i)
      }),
      (k.prototype.sqrTo = function (e, t) {
        e.squareTo(t), this.reduce(t)
      })
    var T,
      S,
      C,
      E = [
        2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97, 101, 103, 107, 109, 113, 127, 131, 137, 139, 149, 151, 157, 163, 167, 173, 179, 181, 191, 193, 197, 199, 211, 223, 227, 229, 233, 239, 241, 251, 257, 263, 269, 271, 277, 281, 283, 293, 307, 311, 313, 317, 331, 337, 347, 349, 353, 359, 367, 373, 379, 383, 389, 397, 401, 409, 419, 421, 431, 433, 439, 443, 449, 457, 461, 463, 467, 479, 487, 491, 499, 503, 509, 521, 523, 541, 547, 557, 563, 569,
        571, 577, 587, 593, 599, 601, 607, 613, 617, 619, 631, 641, 643, 647, 653, 659, 661, 673, 677, 683, 691, 701, 709, 719, 727, 733, 739, 743, 751, 757, 761, 769, 773, 787, 797, 809, 811, 821, 823, 827, 829, 839, 853, 857, 859, 863, 877, 881, 883, 887, 907, 911, 919, 929, 937, 941, 947, 953, 967, 971, 977, 983, 991, 997
      ],
      D = (1 << 26) / E[E.length - 1]
    function N () {
      var e
        ; (e = new Date().getTime()), (S[C++] ^= 255 & e), (S[C++] ^= (e >> 8) & 255), (S[C++] ^= (e >> 16) & 255), (S[C++] ^= (e >> 24) & 255), C >= R && (C -= R)
    }
    if (
      ((i.prototype.chunkSize = function (e) {
        return Math.floor((Math.LN2 * this.DB) / Math.log(e))
      }),
        (i.prototype.toRadix = function (e) {
          if ((null == e && (e = 10), 0 == this.signum() || e < 2 || e > 36)) return '0'
          var t = this.chunkSize(e),
            i = Math.pow(e, t),
            o = d(i),
            a = n(),
            s = n(),
            r = ''
          for (this.divRemTo(o, a, s); a.signum() > 0;) (r = (i + s.intValue()).toString(e).substr(1) + r), a.divRemTo(o, a, s)
          return s.intValue().toString(e) + r
        }),
        (i.prototype.fromRadix = function (e, t) {
          this.fromInt(0), null == t && (t = 10)
          for (var n = this.chunkSize(t), o = Math.pow(t, n), a = !1, s = 0, r = 0, l = 0; l < e.length; ++l) {
            var d = c(e, l)
            d < 0 ? '-' == e.charAt(l) && 0 == this.signum() && (a = !0) : ((r = t * r + d), ++s >= n && (this.dMultiply(o), this.dAddOffset(r, 0), (s = 0), (r = 0)))
          }
          s > 0 && (this.dMultiply(Math.pow(t, s)), this.dAddOffset(r, 0)), a && i.ZERO.subTo(this, this)
        }),
        (i.prototype.fromNumber = function (e, t, n) {
          if ('number' == typeof t)
            if (e < 2) this.fromInt(1)
            else for (this.fromNumber(e, n), this.testBit(e - 1) || this.bitwiseTo(i.ONE.shiftLeft(e - 1), g, this), this.isEven() && this.dAddOffset(1, 0); !this.isProbablePrime(t);) this.dAddOffset(2, 0), this.bitLength() > e && this.subTo(i.ONE.shiftLeft(e - 1), this)
          else {
            var o = new Array(),
              a = 7 & e
              ; (o.length = 1 + (e >> 3)), t.nextBytes(o), a > 0 ? (o[0] &= (1 << a) - 1) : (o[0] = 0), this.fromString(o, 256)
          }
        }),
        (i.prototype.bitwiseTo = function (e, t, i) {
          var n,
            o,
            a = Math.min(e.t, this.t)
          for (n = 0; n < a; ++n) i[n] = t(this[n], e[n])
          if (e.t < this.t) {
            for (o = e.s & this.DM, n = a; n < this.t; ++n) i[n] = t(this[n], o)
            i.t = this.t
          } else {
            for (o = this.s & this.DM, n = a; n < e.t; ++n) i[n] = t(o, e[n])
            i.t = e.t
          }
          ; (i.s = t(this.s, e.s)), i.clamp()
        }),
        (i.prototype.changeBit = function (e, t) {
          var n = i.ONE.shiftLeft(e)
          return this.bitwiseTo(n, t, n), n
        }),
        (i.prototype.addTo = function (e, t) {
          for (var i = 0, n = 0, o = Math.min(e.t, this.t); i < o;) (n += this[i] + e[i]), (t[i++] = n & this.DM), (n >>= this.DB)
          if (e.t < this.t) {
            for (n += e.s; i < this.t;) (n += this[i]), (t[i++] = n & this.DM), (n >>= this.DB)
            n += this.s
          } else {
            for (n += this.s; i < e.t;) (n += e[i]), (t[i++] = n & this.DM), (n >>= this.DB)
            n += e.s
          }
          ; (t.s = n < 0 ? -1 : 0), n > 0 ? (t[i++] = n) : n < -1 && (t[i++] = this.DV + n), (t.t = i), t.clamp()
        }),
        (i.prototype.dMultiply = function (e) {
          ; (this[this.t] = this.am(0, e - 1, this, 0, 0, this.t)), ++this.t, this.clamp()
        }),
        (i.prototype.dAddOffset = function (e, t) {
          if (0 != e) {
            for (; this.t <= t;) this[this.t++] = 0
            for (this[t] += e; this[t] >= this.DV;) (this[t] -= this.DV), ++t >= this.t && (this[this.t++] = 0), ++this[t]
          }
        }),
        (i.prototype.multiplyLowerTo = function (e, t, i) {
          var n,
            o = Math.min(this.t + e.t, t)
          for (i.s = 0, i.t = o; o > 0;) i[--o] = 0
          for (n = i.t - this.t; o < n; ++o) i[o + this.t] = this.am(0, e[o], i, o, 0, this.t)
          for (n = Math.min(e.t, t); o < n; ++o) this.am(0, e[o], i, o, 0, t - o)
          i.clamp()
        }),
        (i.prototype.multiplyUpperTo = function (e, t, i) {
          --t
          var n = (i.t = this.t + e.t - t)
          for (i.s = 0; --n >= 0;) i[n] = 0
          for (n = Math.max(t - this.t, 0); n < e.t; ++n) i[this.t + n - t] = this.am(t - n, e[n], i, 0, 0, this.t + n - t)
          i.clamp(), i.drShiftTo(1, i)
        }),
        (i.prototype.modInt = function (e) {
          if (e <= 0) return 0
          var t = this.DV % e,
            i = this.s < 0 ? e - 1 : 0
          if (this.t > 0)
            if (0 == t) i = this[0] % e
            else for (var n = this.t - 1; n >= 0; --n) i = (t * i + this[n]) % e
          return i
        }),
        (i.prototype.millerRabin = function (e) {
          var t = this.subtract(i.ONE),
            o = t.getLowestSetBit()
          if (o <= 0) return !1
          var a = t.shiftRight(o)
            ; (e = (e + 1) >> 1) > E.length && (e = E.length)
          for (var s = n(), r = 0; r < e; ++r) {
            s.fromInt(E[Math.floor(Math.random() * E.length)])
            var l = s.modPow(a, this)
            if (0 != l.compareTo(i.ONE) && 0 != l.compareTo(t)) {
              for (var c = 1; c++ < o && 0 != l.compareTo(t);) if (0 == (l = l.modPowInt(2, this)).compareTo(i.ONE)) return !1
              if (0 != l.compareTo(t)) return !1
            }
          }
          return !0
        }),
        (i.prototype.clone = function () {
          var e = n()
          return this.copyTo(e), e
        }),
        (i.prototype.intValue = function () {
          if (this.s < 0) {
            if (1 == this.t) return this[0] - this.DV
            if (0 == this.t) return -1
          } else {
            if (1 == this.t) return this[0]
            if (0 == this.t) return 0
          }
          return ((this[1] & ((1 << (32 - this.DB)) - 1)) << this.DB) | this[0]
        }),
        (i.prototype.byteValue = function () {
          return 0 == this.t ? this.s : (this[0] << 24) >> 24
        }),
        (i.prototype.shortValue = function () {
          return 0 == this.t ? this.s : (this[0] << 16) >> 16
        }),
        (i.prototype.signum = function () {
          return this.s < 0 ? -1 : this.t <= 0 || (1 == this.t && this[0] <= 0) ? 0 : 1
        }),
        (i.prototype.toByteArray = function () {
          var e = this.t,
            t = new Array()
          t[0] = this.s
          var i,
            n = this.DB - ((e * this.DB) % 8),
            o = 0
          if (e-- > 0) for (n < this.DB && (i = this[e] >> n) != (this.s & this.DM) >> n && (t[o++] = i | (this.s << (this.DB - n))); e >= 0;) n < 8 ? ((i = (this[e] & ((1 << n) - 1)) << (8 - n)), (i |= this[--e] >> (n += this.DB - 8))) : ((i = (this[e] >> (n -= 8)) & 255), n <= 0 && ((n += this.DB), --e)), 0 != (128 & i) && (i |= -256), 0 == o && (128 & this.s) != (128 & i) && ++o, (o > 0 || i != this.s) && (t[o++] = i)
          return t
        }),
        (i.prototype.equals = function (e) {
          return 0 == this.compareTo(e)
        }),
        (i.prototype.min = function (e) {
          return this.compareTo(e) < 0 ? this : e
        }),
        (i.prototype.max = function (e) {
          return this.compareTo(e) > 0 ? this : e
        }),
        (i.prototype.and = function (e) {
          var t = n()
          return this.bitwiseTo(e, m, t), t
        }),
        (i.prototype.or = function (e) {
          var t = n()
          return this.bitwiseTo(e, g, t), t
        }),
        (i.prototype.xor = function (e) {
          var t = n()
          return this.bitwiseTo(e, f, t), t
        }),
        (i.prototype.andNot = function (e) {
          var t = n()
          return this.bitwiseTo(e, y, t), t
        }),
        (i.prototype.not = function () {
          for (var e = n(), t = 0; t < this.t; ++t) e[t] = this.DM & ~this[t]
          return (e.t = this.t), (e.s = ~this.s), e
        }),
        (i.prototype.shiftLeft = function (e) {
          var t = n()
          return e < 0 ? this.rShiftTo(-e, t) : this.lShiftTo(e, t), t
        }),
        (i.prototype.shiftRight = function (e) {
          var t = n()
          return e < 0 ? this.lShiftTo(-e, t) : this.rShiftTo(e, t), t
        }),
        (i.prototype.getLowestSetBit = function () {
          for (var e = 0; e < this.t; ++e) if (0 != this[e]) return e * this.DB + b(this[e])
          return this.s < 0 ? this.t * this.DB : -1
        }),
        (i.prototype.bitCount = function () {
          for (var e = 0, t = this.s & this.DM, i = 0; i < this.t; ++i) e += v(this[i] ^ t)
          return e
        }),
        (i.prototype.testBit = function (e) {
          var t = Math.floor(e / this.DB)
          return t >= this.t ? 0 != this.s : 0 != (this[t] & (1 << e % this.DB))
        }),
        (i.prototype.setBit = function (e) {
          return this.changeBit(e, g)
        }),
        (i.prototype.clearBit = function (e) {
          return this.changeBit(e, y)
        }),
        (i.prototype.flipBit = function (e) {
          return this.changeBit(e, f)
        }),
        (i.prototype.add = function (e) {
          var t = n()
          return this.addTo(e, t), t
        }),
        (i.prototype.subtract = function (e) {
          var t = n()
          return this.subTo(e, t), t
        }),
        (i.prototype.multiply = function (e) {
          var t = n()
          return this.multiplyTo(e, t), t
        }),
        (i.prototype.divide = function (e) {
          var t = n()
          return this.divRemTo(e, t, null), t
        }),
        (i.prototype.remainder = function (e) {
          var t = n()
          return this.divRemTo(e, null, t), t
        }),
        (i.prototype.divideAndRemainder = function (e) {
          var t = n(),
            i = n()
          return this.divRemTo(e, t, i), new Array(t, i)
        }),
        (i.prototype.modPow = function (e, t) {
          var i,
            o,
            a = e.bitLength(),
            s = d(1)
          if (a <= 0) return s
            ; (i = a < 18 ? 1 : a < 48 ? 3 : a < 144 ? 4 : a < 768 ? 5 : 6), (o = a < 8 ? new h(t) : t.isEven() ? new k(t) : new p(t))
          var r = new Array(),
            l = 3,
            c = i - 1,
            m = (1 << i) - 1
          if (((r[1] = o.convert(this)), i > 1)) {
            var g = n()
            for (o.sqrTo(r[1], g); l <= m;) (r[l] = n()), o.mulTo(g, r[l - 2], r[l]), (l += 2)
          }
          var f,
            y,
            b = e.t - 1,
            v = !0,
            w = n()
          for (a = u(e[b]) - 1; b >= 0;) {
            for (a >= c ? (f = (e[b] >> (a - c)) & m) : ((f = (e[b] & ((1 << (a + 1)) - 1)) << (c - a)), b > 0 && (f |= e[b - 1] >> (this.DB + a - c))), l = i; 0 == (1 & f);) (f >>= 1), --l
            if (((a -= l) < 0 && ((a += this.DB), --b), v)) r[f].copyTo(s), (v = !1)
            else {
              for (; l > 1;) o.sqrTo(s, w), o.sqrTo(w, s), (l -= 2)
              l > 0 ? o.sqrTo(s, w) : ((y = s), (s = w), (w = y)), o.mulTo(w, r[f], s)
            }
            for (; b >= 0 && 0 == (e[b] & (1 << a));) o.sqrTo(s, w), (y = s), (s = w), (w = y), --a < 0 && ((a = this.DB - 1), --b)
          }
          return o.revert(s)
        }),
        (i.prototype.modInverse = function (e) {
          var t = e.isEven()
          if ((this.isEven() && t) || 0 == e.signum()) return i.ZERO
          for (var n = e.clone(), o = this.clone(), a = d(1), s = d(0), r = d(0), l = d(1); 0 != n.signum();) {
            for (; n.isEven();) n.rShiftTo(1, n), t ? ((a.isEven() && s.isEven()) || (a.addTo(this, a), s.subTo(e, s)), a.rShiftTo(1, a)) : s.isEven() || s.subTo(e, s), s.rShiftTo(1, s)
            for (; o.isEven();) o.rShiftTo(1, o), t ? ((r.isEven() && l.isEven()) || (r.addTo(this, r), l.subTo(e, l)), r.rShiftTo(1, r)) : l.isEven() || l.subTo(e, l), l.rShiftTo(1, l)
            n.compareTo(o) >= 0 ? (n.subTo(o, n), t && a.subTo(r, a), s.subTo(l, s)) : (o.subTo(n, o), t && r.subTo(a, r), l.subTo(s, l))
          }
          return 0 != o.compareTo(i.ONE) ? i.ZERO : l.compareTo(e) >= 0 ? l.subtract(e) : l.signum() < 0 ? (l.addTo(e, l), l.signum() < 0 ? l.add(e) : l) : l
        }),
        (i.prototype.pow = function (e) {
          return this.exp(e, new w())
        }),
        (i.prototype.gcd = function (e) {
          var t = this.s < 0 ? this.negate() : this.clone(),
            i = e.s < 0 ? e.negate() : e.clone()
          if (t.compareTo(i) < 0) {
            var n = t
              ; (t = i), (i = n)
          }
          var o = t.getLowestSetBit(),
            a = i.getLowestSetBit()
          if (a < 0) return t
          for (o < a && (a = o), a > 0 && (t.rShiftTo(a, t), i.rShiftTo(a, i)); t.signum() > 0;) (o = t.getLowestSetBit()) > 0 && t.rShiftTo(o, t), (o = i.getLowestSetBit()) > 0 && i.rShiftTo(o, i), t.compareTo(i) >= 0 ? (t.subTo(i, t), t.rShiftTo(1, t)) : (i.subTo(t, i), i.rShiftTo(1, i))
          return a > 0 && i.lShiftTo(a, i), i
        }),
        (i.prototype.isProbablePrime = function (e) {
          var t,
            i = this.abs()
          if (1 == i.t && i[0] <= E[E.length - 1]) {
            for (t = 0; t < E.length; ++t) if (i[0] == E[t]) return !0
            return !1
          }
          if (i.isEven()) return !1
          for (t = 1; t < E.length;) {
            for (var n = E[t], o = t + 1; o < E.length && n < D;) n *= E[o++]
            for (n = i.modInt(n); t < o;) if (n % E[t++] == 0) return !1
          }
          return i.millerRabin(e)
        }),
        (i.prototype.square = function () {
          var e = n()
          return this.squareTo(e), e
        }),
        (i.prototype.Barrett = k),
        null == S)
    ) {
      var x
      if (((S = new Array()), (C = 0), 'undefined' != typeof window && at))
        if (at.getRandomValues) {
          var B = new Uint8Array(32)
          for (at.getRandomValues(B), x = 0; x < 32; ++x) S[C++] = B[x]
        } else if ('Netscape' == navigator.appName && navigator.appVersion < '5') {
          var A = at.random(32)
          for (x = 0; x < A.length; ++x) S[C++] = 255 & A.charCodeAt(x)
        }
      for (; C < R;) (x = Math.floor(65536 * Math.random())), (S[C++] = x >>> 8), (S[C++] = 255 & x)
        ; (C = 0), N()
    }
    function V () {
      if (null == T) {
        for (N(), (T = new L()).init(S), C = 0; C < S.length; ++C) S[C] = 0
        C = 0
      }
      return T.next()
    }
    function I () { }
    function L () {
      ; (this.i = 0), (this.j = 0), (this.S = new Array())
    }
    ; (I.prototype.nextBytes = function (e) {
      var t
      for (t = 0; t < e.length; ++t) e[t] = V()
    }),
      (L.prototype.init = function (e) {
        var t, i, n
        for (t = 0; t < 256; ++t) this.S[t] = t
        for (i = 0, t = 0; t < 256; ++t) (i = (i + this.S[t] + e[t % e.length]) & 255), (n = this.S[t]), (this.S[t] = this.S[i]), (this.S[i] = n)
          ; (this.i = 0), (this.j = 0)
      }),
      (L.prototype.next = function () {
        var e
        return (this.i = (this.i + 1) & 255), (this.j = (this.j + this.S[this.i]) & 255), (e = this.S[this.i]), (this.S[this.i] = this.S[this.j]), (this.S[this.j] = e), this.S[(e + this.S[this.i]) & 255]
      })
    var R = 256
    e.exports = { default: i, BigInteger: i, SecureRandom: I }
  }).call(et)
})({
  get exports () {
    return Ro
  },
  set exports (e) {
    Ro = e
  }
})
class Wo {
  constructor(e, t) {
    ; (this.x = t), (this.q = e)
  }
  equals (e) {
    return e === this || (this.q.equals(e.q) && this.x.equals(e.x))
  }
  toBigInteger () {
    return this.x
  }
  negate () {
    return new Wo(this.q, this.x.negate().mod(this.q))
  }
  add (e) {
    return new Wo(this.q, this.x.add(e.toBigInteger()).mod(this.q))
  }
  subtract (e) {
    return new Wo(this.q, this.x.subtract(e.toBigInteger()).mod(this.q))
  }
  multiply (e) {
    return new Wo(this.q, this.x.multiply(e.toBigInteger()).mod(this.q))
  }
  divide (e) {
    return new Wo(this.q, this.x.multiply(e.toBigInteger().modInverse(this.q)).mod(this.q))
  }
  square () {
    return new Wo(this.q, this.x.square().mod(this.q))
  }
}
class Ko {
  constructor(e, t, i, n) {
    ; (this.curve = e), (this.x = t), (this.y = i), (this.z = null == n ? Ho.ONE : n), (this.zinv = null)
  }
  getX () {
    return null === this.zinv && (this.zinv = this.z.modInverse(this.curve.q)), this.curve.fromBigInteger(this.x.toBigInteger().multiply(this.zinv).mod(this.curve.q))
  }
  getY () {
    return null === this.zinv && (this.zinv = this.z.modInverse(this.curve.q)), this.curve.fromBigInteger(this.y.toBigInteger().multiply(this.zinv).mod(this.curve.q))
  }
  equals (e) {
    if (e === this) return !0
    if (this.isInfinity()) return e.isInfinity()
    if (e.isInfinity()) return this.isInfinity()
    if (!e.y.toBigInteger().multiply(this.z).subtract(this.y.toBigInteger().multiply(e.z)).mod(this.curve.q).equals(Ho.ZERO)) return !1
    return e.x.toBigInteger().multiply(this.z).subtract(this.x.toBigInteger().multiply(e.z)).mod(this.curve.q).equals(Ho.ZERO)
  }
  isInfinity () {
    return (null === this.x && null === this.y) || (this.z.equals(Ho.ZERO) && !this.y.toBigInteger().equals(Ho.ZERO))
  }
  negate () {
    return new Ko(this.curve, this.x, this.y.negate(), this.z)
  }
  add (e) {
    if (this.isInfinity()) return e
    if (e.isInfinity()) return this
    const t = this.x.toBigInteger(),
      i = this.y.toBigInteger(),
      n = this.z,
      o = e.x.toBigInteger(),
      a = e.y.toBigInteger(),
      s = e.z,
      r = this.curve.q,
      l = t.multiply(s).mod(r),
      c = o.multiply(n).mod(r),
      d = l.subtract(c),
      u = i.multiply(s).mod(r),
      h = a.multiply(n).mod(r),
      p = u.subtract(h)
    if (Ho.ZERO.equals(d)) return Ho.ZERO.equals(p) ? this.twice() : this.curve.infinity
    const m = l.add(c),
      g = n.multiply(s).mod(r),
      f = d.square().mod(r),
      y = d.multiply(f).mod(r),
      b = g.multiply(p.square()).subtract(m.multiply(f)).mod(r),
      v = d.multiply(b).mod(r),
      w = p.multiply(f.multiply(l).subtract(b)).subtract(u.multiply(y)).mod(r),
      _ = y.multiply(g).mod(r)
    return new Ko(this.curve, this.curve.fromBigInteger(v), this.curve.fromBigInteger(w), _)
  }
  twice () {
    if (this.isInfinity()) return this
    if (!this.y.toBigInteger().signum()) return this.curve.infinity
    const e = this.x.toBigInteger(),
      t = this.y.toBigInteger(),
      i = this.z,
      n = this.curve.q,
      o = this.curve.a.toBigInteger(),
      a = e.square().multiply(Go).add(o.multiply(i.square())).mod(n),
      s = t.shiftLeft(1).multiply(i).mod(n),
      r = t.square().mod(n),
      l = r.multiply(e).multiply(i).mod(n),
      c = s.square().mod(n),
      d = a.square().subtract(l.shiftLeft(3)).mod(n),
      u = s.multiply(d).mod(n),
      h = a.multiply(l.shiftLeft(2).subtract(d)).subtract(c.shiftLeft(1).multiply(r)).mod(n),
      p = s.multiply(c).mod(n)
    return new Ko(this.curve, this.curve.fromBigInteger(u), this.curve.fromBigInteger(h), p)
  }
  multiply (e) {
    if (this.isInfinity()) return this
    if (!e.signum()) return this.curve.infinity
    const t = e.multiply(Go),
      i = this.negate()
    let n = this
    for (let o = t.bitLength() - 2; o > 0; o--) {
      n = n.twice()
      const a = t.testBit(o)
      a !== e.testBit(o) && (n = n.add(a ? this : i))
    }
    return n
  }
}

const { BigInteger: Ho } = Ro,
  $o = new Ho('2'),
  Go = new Ho('3')
var Jo = {
  ECPointFp: Ko,
  ECCurveFp: class {
    constructor(e, t, i) {
      ; (this.q = e), (this.a = this.fromBigInteger(t)), (this.b = this.fromBigInteger(i)), (this.infinity = new Ko(this, null, null))
    }
    equals (e) {
      return e === this || (this.q.equals(e.q) && this.a.equals(e.a) && this.b.equals(e.b))
    }
    fromBigInteger (e) {
      return new Wo(this.q, e)
    }
    decodePointHex (e) {
      switch (parseInt(e.substr(0, 2), 16)) {
        case 0:
          return this.infinity
        case 2:
        case 3:
          const t = this.fromBigInteger(new Ho(e.substr(2), 16))
          let i = this.fromBigInteger(
            t
              .multiply(t.square())
              .add(t.multiply(this.a))
              .add(this.b)
              .toBigInteger()
              .modPow(this.q.divide(new Ho('4')).add(Ho.ONE), this.q)
          )
          return (
            i
              .toBigInteger()
              .mod($o)
              .equals(new Ho(e.substr(0, 2), 16).subtract($o)) || (i = i.negate()),
            new Ko(this, t, i)
          )
        case 4:
        case 6:
        case 7:
          const n = (e.length - 2) / 2,
            o = e.substr(2, n),
            a = e.substr(n + 2, n)
          return new Ko(this, this.fromBigInteger(new Ho(o, 16)), this.fromBigInteger(new Ho(a, 16)))
        default:
          return null
      }
    }
  }
}
const { BigInteger: Yo, SecureRandom: Zo } = Ro,
  { ECCurveFp: Xo } = Jo,
  Qo = new Zo()
const { BigInteger: Po } = Ro,
  { curve: ea, G: ta, n: ia } = na()
function oa (e, t) {
  return e.length >= t ? e : new Array(t - e.length + 1).join('0') + e
}
function na () {
  const e = new Yo('FFFFFFFEFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF00000000FFFFFFFFFFFFFFFF', 16),
    t = new Yo('FFFFFFFEFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF00000000FFFFFFFFFFFFFFFC', 16),
    i = new Yo('28E9FA9E9D9F5E344D5A9E4BCF6509A7F39789F515AB8F92DDBCBD414D940E93', 16),
    n = new Xo(e, t, i),
    o = n.decodePointHex('0432C4AE2C1F1981195F9904466A39C9948FE30BBFF2660BE1715A4589334C74C7BC3736A2F4F6779C59BDCEE36B692153D0A9877CC62A474002DF32E52139F0A0')
  return { curve: n, G: o, n: new Yo('FFFFFFFEFFFFFFFFFFFFFFFFFFFFFFFF7203DF6B21C6052B53BBF40939D54123', 16) }
}

var ba = {
  getGlobalCurve: function () {
    return ea
  },
  generateEcparam: na,
  generateKeyPairHex: function (e, t, i) {
    const n = (e ? new Yo(e, t, i) : new Yo(ia.bitLength(), Qo)).mod(ia.subtract(Yo.ONE)).add(Yo.ONE),
      o = oa(n.toString(16), 64),
      a = ta.multiply(n)
    return { privateKey: o, publicKey: '04' + oa(a.getX().toBigInteger().toString(16), 64) + oa(a.getY().toBigInteger().toString(16), 64) }
  },
  compressPublicKeyHex: function (e) {
    if (130 !== e.length) throw new Error('Invalid public key to compress')
    const t = (e.length - 2) / 2,
      i = e.substr(2, t)
    let n = '03'
    return new Yo(e.substr(t + 2, t), 16).mod(new Yo('2')).equals(Yo.ZERO) && (n = '02'), n + i
  },
  utf8ToHex: function (e) {
    const t = (e = unescape(encodeURIComponent(e))).length,
      i = []
    for (let o = 0; o < t; o++) i[o >>> 2] |= (255 & e.charCodeAt(o)) << (24 - (o % 4) * 8)
    const n = []
    for (let o = 0; o < t; o++) {
      const e = (i[o >>> 2] >>> (24 - (o % 4) * 8)) & 255
      n.push((e >>> 4).toString(16)), n.push((15 & e).toString(16))
    }
    return n.join('')
  },
  leftPad: oa,
  arrayToHex: function (e) {
    return e.map((e) => (1 === (e = e.toString(16)).length ? '0' + e : e)).join('')
  },
  arrayToUtf8: function (e) {
    const t = []
    let i = 0
    for (let n = 0; n < 2 * e.length; n += 2) (t[n >>> 3] |= parseInt(e[i], 10) << (24 - (n % 8) * 4)), i++
    try {
      const i = []
      for (let n = 0; n < e.length; n++) {
        const e = (t[n >>> 2] >>> (24 - (n % 4) * 8)) & 255
        i.push(String.fromCharCode(e))
      }
      return decodeURIComponent(escape(i.join('')))
    } catch (Bu) {
      throw new Error('Malformed UTF-8 data')
    }
  },
  hexToArray: function (e) {
    const t = []
    let i = e.length
    i % 2 != 0 && (e = oa(e, i + 1)), (i = e.length)
    for (let n = 0; n < i; n += 2) t.push(parseInt(e.substr(n, 2), 16))
    return t
  },
  verifyPublicKey: function (e) {
    const t = ea.decodePointHex(e)
    if (!t) return !1
    const i = t.getX()
    return t
      .getY()
      .square()
      .equals(i.multiply(i.square()).add(i.multiply(ea.a)).add(ea.b))
  },
  comparePublicKeyHex: function (e, t) {
    const i = ea.decodePointHex(e)
    if (!i) return !1
    const n = ea.decodePointHex(t)
    return !!n && i.equals(n)
  }
}

const sa = new Uint32Array(68),
  ra = new Uint32Array(64)
function la (e, t) {
  const i = 31 & t
  return (e << i) | (e >>> (32 - i))
}
function ca (e, t) {
  const i = []
  for (let n = e.length - 1; n >= 0; n--) i[n] = 255 & (e[n] ^ t[n])
  return i
}
function da (e) {
  return e ^ la(e, 9) ^ la(e, 17)
}
function ua (e) {
  let t = 8 * e.length,
    i = t % 512
  i = i >= 448 ? 512 - (i % 448) - 1 : 448 - i - 1
  const n = new Array((i - 7) / 8),
    o = new Array(8)
  for (let u = 0, h = n.length; u < h; u++) n[u] = 0
  for (let u = 0, h = o.length; u < h; u++) o[u] = 0
  t = t.toString(2)
  for (let u = 7; u >= 0; u--)
    if (t.length > 8) {
      const e = t.length - 8
        ; (o[u] = parseInt(t.substr(e), 2)), (t = t.substr(0, e))
    } else t.length > 0 && ((o[u] = parseInt(t, 2)), (t = ''))
  const a = new Uint8Array([...e, 128, ...n, ...o]),
    s = new DataView(a.buffer, 0),
    r = a.length / 64,
    l = new Uint32Array([1937774191, 1226093241, 388252375, 3666478592, 2842636476, 372324522, 3817729613, 2969243214])
  for (let u = 0; u < r; u++) {
    sa.fill(0), ra.fill(0)
    const e = 16 * u
    for (let l = 0; l < 16; l++) sa[l] = s.getUint32(4 * (e + l), !1)
    for (let s = 16; s < 68; s++) sa[s] = (c = sa[s - 16] ^ sa[s - 9] ^ la(sa[s - 3], 15)) ^ la(c, 15) ^ la(c, 23) ^ la(sa[s - 13], 7) ^ sa[s - 6]
    for (let s = 0; s < 64; s++) ra[s] = sa[s] ^ sa[s + 4]
    const t = 2043430169,
      i = 2055708042
    let n,
      o,
      a,
      r,
      d,
      h = l[0],
      p = l[1],
      m = l[2],
      g = l[3],
      f = l[4],
      y = l[5],
      b = l[6],
      v = l[7]
    for (let s = 0; s < 64; s++) (d = s >= 0 && s <= 15 ? t : i), (n = la(la(h, 12) + f + la(d, s), 7)), (o = n ^ la(h, 12)), (a = (s >= 0 && s <= 15 ? h ^ p ^ m : (h & p) | (h & m) | (p & m)) + g + o + ra[s]), (r = (s >= 0 && s <= 15 ? f ^ y ^ b : (f & y) | (~f & b)) + v + n + sa[s]), (g = m), (m = la(p, 9)), (p = h), (h = a), (v = b), (b = la(y, 19)), (y = f), (f = da(r))
      ; (l[0] ^= h), (l[1] ^= p), (l[2] ^= m), (l[3] ^= g), (l[4] ^= f), (l[5] ^= y), (l[6] ^= b), (l[7] ^= v)
  }
  var c
  const d = []
  for (let u = 0, h = l.length; u < h; u++) {
    const e = l[u]
    d.push((4278190080 & e) >>> 24, (16711680 & e) >>> 16, (65280 & e) >>> 8, 255 & e)
  }
  return d
}
const ha = new Uint8Array(64),
  pa = new Uint8Array(64)
for (let Mm = 0; Mm < 64; Mm++) (ha[Mm] = 54), (pa[Mm] = 92)
var ma = {
  sm3: ua,
  hmac: function (e, t) {
    for (t.length > 64 && (t = ua(t)); t.length < 64;) t.push(0)
    const i = ca(t, ha),
      n = ca(t, pa),
      o = ua([...i, ...e])
    return ua([...n, ...o])
  }
}
class Oo {
  constructor() {
    ; (this.tlv = null), (this.t = '00'), (this.l = '00'), (this.v = '')
  }
  getEncodedHex () {
    return this.tlv || ((this.v = this.getValue()), (this.l = this.getLength()), (this.tlv = this.t + this.l + this.v)), this.tlv
  }
  getLength () {
    const e = this.v.length / 2
    let t = e.toString(16)
    if ((t.length % 2 == 1 && (t = '0' + t), e < 128)) return t
    return (128 + t.length / 2).toString(16) + t
  }
  getValue () {
    return ''
  }
}
class Mo extends Oo {
  constructor(e) {
    super(),
      (this.t = '02'),
      e &&
      (this.v = (function (e) {
        let t = e.toString(16)
        if ('-' !== t[0]) t.length % 2 == 1 ? (t = '0' + t) : t.match(/^[0-7]/) || (t = '00' + t)
        else {
          t = t.substr(1)
          let i = t.length
          i % 2 == 1 ? (i += 1) : t.match(/^[0-7]/) || (i += 2)
          let n = ''
          for (let e = 0; e < i; e++) n += 'f'
            ; (n = new Po(n, 16)), (t = n.xor(e).add(Po.ONE)), (t = t.toString(16).replace(/^-/, ''))
        }
        return t
      })(e))
  }
  getValue () {
    return this.v
  }
}
class jo extends Oo {
  constructor(e) {
    super(), (this.t = '30'), (this.asn1Array = e)
  }
  getValue () {
    return (this.v = this.asn1Array.map((e) => e.getEncodedHex()).join('')), this.v
  }
}
var zo = {
  encodeDer (e, t) {
    const i = new Mo(e),
      n = new Mo(t)
    return new jo([i, n]).getEncodedHex()
  },
  decodeDer (e) {
    const t = qo(e, 0),
      i = qo(e, t),
      n = Uo(e, t),
      o = e.substr(i, 2 * n),
      a = i + o.length,
      s = qo(e, a),
      r = Uo(e, a),
      l = e.substr(s, 2 * r)
    return { r: new Po(o, 16), s: new Po(l, 16) }
  }
}
const { BigInteger: ga } = Ro,
  { encodeDer: fa, decodeDer: ya } = zo,
  va = ma.sm3,
  { G: wa, curve: _a, n: ka } = ba.generateEcparam()

function doDecrypt (e, t, i = 1, { output: n = 'string' } = {}) {
  t = new ga(t, 16)
  let o = e.substr(128, 64),
    a = e.substr(192)
  0 === i && ((o = e.substr(e.length - 64)), (a = e.substr(128, e.length - 128 - 64)))
  const s = ba.hexToArray(a),
    r = ba
      .getGlobalCurve()
      .decodePointHex('04' + e.substr(0, 128))
      .multiply(t),
    l = ba.hexToArray(ba.leftPad(r.getX().toBigInteger().toRadix(16), 64)),
    c = ba.hexToArray(ba.leftPad(r.getY().toBigInteger().toRadix(16), 64))
  let d = 1,
    u = 0,
    h = []
  const p = [].concat(l, c),
    m = () => {
      ; (h = va([...p, (d >> 24) & 255, (d >> 16) & 255, (d >> 8) & 255, 255 & d])), d++, (u = 0)
    }
  m()
  for (let g = 0, f = s.length; g < f; g++) u === h.length && m(), (s[g] ^= 255 & h[u++])
  return ba.arrayToHex(va([].concat(l, s, c))) === o.toLowerCase() ? ('array' === n ? s : ba.arrayToUtf8(s)) : 'array' === n ? [] : ''
}

const Ba = 16,
  Aa = [
    214, 144, 233, 254, 204, 225, 61, 183, 22, 182, 20, 194, 40, 251, 44, 5, 43, 103, 154, 118, 42, 190, 4, 195, 170, 68, 19, 38, 73, 134, 6, 153, 156, 66, 80, 244, 145, 239, 152, 122, 51, 84, 11, 67, 237, 207, 172, 98, 228, 179, 28, 169, 201, 8, 232, 149, 128, 223, 148, 250, 117, 143, 63, 166, 71, 7, 167, 252, 243, 115, 23, 186, 131, 89, 60, 25, 230, 133, 79, 168, 104, 107, 129, 178, 113, 100, 218, 139, 248, 235, 15, 75, 112, 86, 157, 53, 30, 36, 14, 94, 99, 88, 209, 162, 37, 34, 124, 59, 1,
    33, 120, 135, 212, 0, 70, 87, 159, 211, 39, 82, 76, 54, 2, 231, 160, 196, 200, 158, 234, 191, 138, 210, 64, 199, 56, 181, 163, 247, 242, 206, 249, 97, 21, 161, 224, 174, 93, 164, 155, 52, 26, 85, 173, 147, 50, 48, 245, 140, 177, 227, 29, 246, 226, 46, 130, 102, 202, 96, 192, 41, 35, 171, 13, 83, 78, 111, 213, 219, 55, 69, 222, 253, 142, 47, 3, 255, 106, 114, 109, 108, 91, 81, 141, 27, 175, 146, 187, 221, 188, 127, 17, 217, 92, 65, 31, 16, 90, 216, 10, 193, 49, 136, 165, 205, 123, 189, 45,
    116, 208, 18, 184, 229, 180, 176, 137, 105, 151, 74, 12, 150, 119, 126, 101, 185, 241, 9, 197, 110, 198, 132, 24, 240, 125, 236, 58, 220, 77, 32, 121, 238, 95, 62, 215, 203, 57, 72
  ],
  Va = [462357, 472066609, 943670861, 1415275113, 1886879365, 2358483617, 2830087869, 3301692121, 3773296373, 4228057617, 404694573, 876298825, 1347903077, 1819507329, 2291111581, 2762715833, 3234320085, 3705924337, 4177462797, 337322537, 808926789, 1280531041, 1752135293, 2223739545, 2695343797, 3166948049, 3638552301, 4110090761, 269950501, 741554753, 1213159005, 1684763257]

function Ia (e) {
  const t = []
  for (let i = 0, n = e.length; i < n; i += 2) t.push(parseInt(e.substr(i, 2), 16))
  return t
}
function La (e, t) {
  return (e << t) | (e >>> (32 - t))
}
function Ra (e) {
  return ((255 & Aa[(e >>> 24) & 255]) << 24) | ((255 & Aa[(e >>> 16) & 255]) << 16) | ((255 & Aa[(e >>> 8) & 255]) << 8) | (255 & Aa[255 & e])
}
function Pa (e) {
  return e ^ La(e, 2) ^ La(e, 10) ^ La(e, 18) ^ La(e, 24)
}
function Ma (e, t, i) {
  const n = new Array(4),
    o = new Array(4)
  for (let a = 0; a < 4; a++) (o[0] = 255 & e[4 * a]), (o[1] = 255 & e[4 * a + 1]), (o[2] = 255 & e[4 * a + 2]), (o[3] = 255 & e[4 * a + 3]), (n[a] = (o[0] << 24) | (o[1] << 16) | (o[2] << 8) | o[3])
  for (let a, s = 0; s < 32; s += 4) (a = n[1] ^ n[2] ^ n[3] ^ i[s + 0]), (n[0] ^= Pa(Ra(a))), (a = n[2] ^ n[3] ^ n[0] ^ i[s + 1]), (n[1] ^= Pa(Ra(a))), (a = n[3] ^ n[0] ^ n[1] ^ i[s + 2]), (n[2] ^= Pa(Ra(a))), (a = n[0] ^ n[1] ^ n[2] ^ i[s + 3]), (n[3] ^= Pa(Ra(a)))
  for (let a = 0; a < 16; a += 4) (t[a] = (n[3 - a / 4] >>> 24) & 255), (t[a + 1] = (n[3 - a / 4] >>> 16) & 255), (t[a + 2] = (n[3 - a / 4] >>> 8) & 255), (t[a + 3] = 255 & n[3 - a / 4])
}
function Oa (e) {
  return e ^ La(e, 13) ^ La(e, 23)
}

function ja (e, t, i, { padding: n = 'pkcs#7', mode: o, iv: a = [], output: s = 'string' } = {}) {
  if ('cbc' === o && ('string' == typeof a && (a = Ia(a)), 16 !== a.length)) throw new Error('iv is invalid')
  if (('string' == typeof t && (t = Ia(t)), 16 !== t.length)) throw new Error('key is invalid')
  if (
    ((e =
      'string' == typeof e
        ? 0 !== i
          ? (function (e) {
            const t = []
            for (let i = 0, n = e.length; i < n; i++) {
              const n = e.codePointAt(i)
              if (n <= 127) t.push(n)
              else if (n <= 2047) t.push(192 | (n >>> 6)), t.push(128 | (63 & n))
              else if (n <= 55295 || (n >= 57344 && n <= 65535)) t.push(224 | (n >>> 12)), t.push(128 | ((n >>> 6) & 63)), t.push(128 | (63 & n))
              else {
                if (!(n >= 65536 && n <= 1114111)) throw (t.push(n), new Error('input is not supported'))
                i++, t.push(240 | ((n >>> 18) & 28)), t.push(128 | ((n >>> 12) & 63)), t.push(128 | ((n >>> 6) & 63)), t.push(128 | (63 & n))
              }
            }
            return t
          })(e)
          : Ia(e)
        : [...e]),
      ('pkcs#5' === n || 'pkcs#7' === n) && 0 !== i)
  ) {
    const t = Ba - (e.length % Ba)
    for (let i = 0; i < t; i++) e.push(t)
  }
  const r = new Array(32)
  !(function (e, t, i) {
    const n = new Array(4),
      o = new Array(4)
    for (let a = 0; a < 4; a++) (o[0] = 255 & e[0 + 4 * a]), (o[1] = 255 & e[1 + 4 * a]), (o[2] = 255 & e[2 + 4 * a]), (o[3] = 255 & e[3 + 4 * a]), (n[a] = (o[0] << 24) | (o[1] << 16) | (o[2] << 8) | o[3])
      ; (n[0] ^= 2746333894), (n[1] ^= 1453994832), (n[2] ^= 1736282519), (n[3] ^= 2993693404)
    for (let a, s = 0; s < 32; s += 4) (a = n[1] ^ n[2] ^ n[3] ^ Va[s + 0]), (t[s + 0] = n[0] ^= Oa(Ra(a))), (a = n[2] ^ n[3] ^ n[0] ^ Va[s + 1]), (t[s + 1] = n[1] ^= Oa(Ra(a))), (a = n[3] ^ n[0] ^ n[1] ^ Va[s + 2]), (t[s + 2] = n[2] ^= Oa(Ra(a))), (a = n[0] ^ n[1] ^ n[2] ^ Va[s + 3]), (t[s + 3] = n[3] ^= Oa(Ra(a)))
    if (0 === i) for (let a, s = 0; s < 16; s++) (a = t[s]), (t[s] = t[31 - s]), (t[31 - s] = a)
  })(t, r, i)
  const l = []
  let c = a,
    d = e.length,
    u = 0
  for (; d >= Ba;) {
    const t = e.slice(u, u + 16),
      n = new Array(16)
    if ('cbc' === o) for (let e = 0; e < Ba; e++) 0 !== i && (t[e] ^= c[e])
    Ma(t, n, r)
    for (let e = 0; e < Ba; e++) 'cbc' === o && 0 === i && (n[e] ^= c[e]), (l[u + e] = n[e])
    'cbc' === o && (c = 0 !== i ? n : t), (d -= Ba), (u += Ba)
  }
  if (('pkcs#5' === n || 'pkcs#7' === n) && 0 === i) {
    const e = l.length,
      t = l[e - 1]
    for (let i = 1; i <= t; i++) if (l[e - i] !== t) throw new Error('padding is invalid')
    l.splice(e - t, t)
  }
  return 'array' !== s
    ? 0 !== i
      ? l.map((e) => (1 === (e = e.toString(16)).length ? '0' + e : e)).join('')
      : (function (e) {
        const t = []
        for (let i = 0, n = e.length; i < n; i++) e[i] >= 240 && e[i] <= 247 ? (t.push(String.fromCodePoint(((7 & e[i]) << 18) + ((63 & e[i + 1]) << 12) + ((63 & e[i + 2]) << 6) + (63 & e[i + 3]))), (i += 3)) : e[i] >= 224 && e[i] <= 239 ? (t.push(String.fromCodePoint(((15 & e[i]) << 12) + ((63 & e[i + 1]) << 6) + (63 & e[i + 2]))), (i += 2)) : e[i] >= 192 && e[i] <= 223 ? (t.push(String.fromCodePoint(((31 & e[i]) << 6) + (63 & e[i + 1]))), i++) : t.push(String.fromCodePoint(e[i]))
        return t.join('')
      })(l)
    : l
}
function Ta (e, t, i = '1234567812345678') {
  i = ba.utf8ToHex(i)
  const n = ba.leftPad(wa.curve.a.toBigInteger().toRadix(16), 64),
    o = ba.leftPad(wa.curve.b.toBigInteger().toRadix(16), 64),
    a = ba.leftPad(wa.getX().toBigInteger().toRadix(16), 64),
    s = ba.leftPad(wa.getY().toBigInteger().toRadix(16), 64)
  let r, l
  if (128 === t.length) (r = t.substr(0, 64)), (l = t.substr(64, 64))
  else {
    const e = wa.curve.decodePointHex(t)
      ; (r = ba.leftPad(e.getX().toBigInteger().toRadix(16), 64)), (l = ba.leftPad(e.getY().toBigInteger().toRadix(16), 64))
  }
  const c = ba.hexToArray(i + n + o + a + s + r + l),
    d = 4 * i.length
  c.unshift(255 & d), c.unshift((d >> 8) & 255)
  const u = va(c)
  return ba.arrayToHex(va(u.concat(ba.hexToArray(e))))
}
function Sa () {
  const e = ba.generateKeyPairHex(),
    t = _a.decodePointHex(e.publicKey)
  return (e.k = new ga(e.privateKey, 16)), (e.x1 = t.getX().toBigInteger()), e
}

var Ca = {
  generateKeyPairHex: ba.generateKeyPairHex,
  compressPublicKeyHex: ba.compressPublicKeyHex,
  comparePublicKeyHex: ba.comparePublicKeyHex,
  doEncrypt: function (e, t, i = 1) {
    ; (e = 'string' == typeof e ? ba.hexToArray(ba.utf8ToHex(e)) : Array.prototype.slice.call(e)), (t = ba.getGlobalCurve().decodePointHex(t))
    const n = ba.generateKeyPairHex(),
      o = new ga(n.privateKey, 16)
    let a = n.publicKey
    a.length > 128 && (a = a.substr(a.length - 128))
    const s = t.multiply(o),
      r = ba.hexToArray(ba.leftPad(s.getX().toBigInteger().toRadix(16), 64)),
      l = ba.hexToArray(ba.leftPad(s.getY().toBigInteger().toRadix(16), 64)),
      c = ba.arrayToHex(va([].concat(r, e, l)))
    let d = 1,
      u = 0,
      h = []
    const p = [].concat(r, l),
      m = () => {
        ; (h = va([...p, (d >> 24) & 255, (d >> 16) & 255, (d >> 8) & 255, 255 & d])), d++, (u = 0)
      }
    m()
    for (let f = 0, y = e.length; f < y; f++) u === h.length && m(), (e[f] ^= 255 & h[u++])
    const g = ba.arrayToHex(e)
    return 0 === i ? a + g + c : a + c + g
  },
  doDecrypt: function (e, t, i = 1, { output: n = 'string' } = {}) {
    t = new ga(t, 16)
    let o = e.substr(128, 64),
      a = e.substr(192)
    0 === i && ((o = e.substr(e.length - 64)), (a = e.substr(128, e.length - 128 - 64)))
    const s = ba.hexToArray(a),
      r = ba
        .getGlobalCurve()
        .decodePointHex('04' + e.substr(0, 128))
        .multiply(t),
      l = ba.hexToArray(ba.leftPad(r.getX().toBigInteger().toRadix(16), 64)),
      c = ba.hexToArray(ba.leftPad(r.getY().toBigInteger().toRadix(16), 64))
    let d = 1,
      u = 0,
      h = []
    const p = [].concat(l, c),
      m = () => {
        ; (h = va([...p, (d >> 24) & 255, (d >> 16) & 255, (d >> 8) & 255, 255 & d])), d++, (u = 0)
      }
    m()
    for (let g = 0, f = s.length; g < f; g++) u === h.length && m(), (s[g] ^= 255 & h[u++])
    return ba.arrayToHex(va([].concat(l, s, c))) === o.toLowerCase() ? ('array' === n ? s : ba.arrayToUtf8(s)) : 'array' === n ? [] : ''
  },
  doSignature: function (e, t, { pointPool: i, der: n, hash: o, publicKey: a, userId: s } = {}) {
    let r = 'string' == typeof e ? ba.utf8ToHex(e) : ba.arrayToHex(e)
    o &&
      ((a =
        a ||
        (function (e) {
          const t = wa.multiply(new ga(e, 16)),
            i = ba.leftPad(t.getX().toBigInteger().toString(16), 64),
            n = ba.leftPad(t.getY().toBigInteger().toString(16), 64)
          return '04' + i + n
        })(t)),
        (r = Ta(r, a, s)))
    const l = new ga(t, 16),
      c = new ga(r, 16)
    let d = null,
      u = null,
      h = null
    do {
      do {
        let e
          ; (e = i && i.length ? i.pop() : Sa()), (d = e.k), (u = c.add(e.x1).mod(ka))
      } while (u.equals(ga.ZERO) || u.add(d).equals(ka))
      h = l
        .add(ga.ONE)
        .modInverse(ka)
        .multiply(d.subtract(u.multiply(l)))
        .mod(ka)
    } while (h.equals(ga.ZERO))
    return n ? fa(u, h) : ba.leftPad(u.toString(16), 64) + ba.leftPad(h.toString(16), 64)
  },
  doVerifySignature: function (e, t, i, { der: n, hash: o, userId: a } = {}) {
    let s,
      r,
      l = 'string' == typeof e ? ba.utf8ToHex(e) : ba.arrayToHex(e)
    if ((o && (l = Ta(l, i, a)), n)) {
      const e = ya(t)
        ; (s = e.r), (r = e.s)
    } else (s = new ga(t.substring(0, 64), 16)), (r = new ga(t.substring(64), 16))
    const c = _a.decodePointHex(i),
      d = new ga(l, 16),
      u = s.add(r).mod(ka)
    if (u.equals(ga.ZERO)) return !1
    const h = wa.multiply(r).add(c.multiply(u)),
      p = d.add(h.getX().toBigInteger()).mod(ka)
    return s.equals(p)
  }
  // getPoint: Sa,
  // verifyPublicKey: ba.verifyPublicKey
}

var Fa = {
  encrypt: (e, t, i) => ja(e, t, 1, i),
  decrypt: (e, t, i) => ja(e, t, 0, i)
},
  Ua = {
    sm2: Ca,
    sm4: Fa
  }
let qa = Ua.sm2
const za = {
  encrypt (e, t) {
    let i
    return (i = 'string' == typeof e ? e : JSON.stringify(e)), qa.doEncrypt(i, t, 1)
  },
  dycrypt: (e, t) => qa.doDecrypt(e, t, 1),
  sign: (e, t) => qa.doSignature(e, t, { hash: !0, der: !0 }),
  verify: (e, t, i) => qa.doVerifySignature(e, t, i, { hash: !0, der: !0 })
}

let Ha = Ua.sm4
const $a = {
  encrypt (e, t) {
    let i
    return (i = 'string' == typeof e ? e : JSON.stringify(e)), Ha.encrypt(i, t)
  },
  dycrypt: (e, t) => Ha.decrypt(e, t)
},
  Ka = function (e, t, i, n, o) {
    null == t && (t = {})
    var a = []
    for (var s in i) null != i[s].mode && 'encrypt' == i[s].mode && a.push(s)
    if (a.length > 0) {
      var r = Qe.getAesKey(16)
      for (var s in ((t.sKey = Io.encrypt(r, Lo)), a)) i[a[s]] = En.enncrpt(i[a[s]].data, r)
    }
    return (t['content-type'] = 'application/x-www-form-urlencoded; charset=utf-8'), Ja(e, t, i, n, o)
  },
  Ga = 'https://gbt.sacinfo.org.cn/gateway',
  Wa = '0085bf0bd9747727bf1f9baf41c4f8f6eb14dba6e50a9cf0ee5de739953b351d17',
  Ja = function (e, t, n, o, a) {
    var s = !0
    if (
      ((null == a && 0 != e.indexOf('/api/app/') && 0 != e.indexOf('/api/user/')) || (s = !1),
        null == n && (n = {}),
        null == t && (t = {}),
        // null == t.Authorization && (t.Authorization = Te.state.token_head + Te.state.auth_token),
        null == t.Authorization && (t.Authorization = ''),
        s && (t.Encode = !0),
        // null != o && uni.showLoading({ title: o, mask: !0 }),
        s)
    ) {
      const e = JSON.stringify(n)
      var r = (function (e) {
        for (var t = '', i = 0; i < e.length; i++) t += e.charCodeAt(i).toString(16)
        return t
      })(((e) => [...Array(e)].map(() => Math.floor(16 * Math.random()).toString(16)).join(''))(16)),
        l = za.encrypt(r, '0435983bab5f6e749f551001b525e8f1444aea73b53ef266f94b613f3e15e5fc2b564dba84f1dbad1f1bbec7a7ff24ce64dd1a1cd9fec99a57a97a8b434a39bbab'),
        c = $a.encrypt(e, r),
        d = za.sign(e, Wa)
      n = {
        data: c,
        keyCipher: l,
        signature: d
      }
    }
    return { data: n, url: (null == a ? Ga : a) + e }
  },
  Ya = {
    postFormRequestOthRole: function (e, t, i, n, o, a) {
      return null == t && (t = {}), (t.oth_role = o), Ka(e, t, i, n, a)
    },
    postFormRequest: Ka,
    postJsonRequest: function (e, t, i, n, o) {
      return null == t && (t = {}), (t['content-type'] = 'application/json'), Ja(e, t, i, n, o)
    },
    downloadFile: async function (e, t, i) {
      return (
        null == t && (t = {}),
        (t.g_token = Te.state.auth_token),
        (t.g_client = Te.state.client_id),
        uni.downloadFile({
          url: Ga + e,
          header: t,
          success: (e) => {
            i(e)
          }
        })
      )
    },
    getRootUrl: function (e, t) {
      var i = Ga + e,
        n = Ga + e
      if (null != t) for (let o in t) t[o] && ((i += n === i ? '?' : '&'), (i += o + '=' + t[o]))
      return i
    }
  },
  ps = function (e, t) {
    return Ya.postFormRequest('/api/dcpsp' + e, null, t, null)
  }

export function jiemi (res) {
  if (!res || !res.keyCipher || !res.data) {
    return ''
  }
  let u = res.keyCipher
  u.startsWith('04') && (u = u.substring(2))
  let h = doDecrypt(u, '0085bf0bd9747727bf1f9baf41c4f8f6eb14dba6e50a9cf0ee5de739953b351d17')
  // console.log(h);
  let p = $a.dycrypt(res.data, h)
  return p
}
import qs from 'qs'
export function jiami (url, data) {
  let n = ps(url, data)
  let {
    data: data2,
    keyCipher,
    signature
  } = n.data
  return { ...n, data: `data=${data2}&keyCipher=${keyCipher}&signature=${signature}` }
}
export function stdType (e) {
  switch (e) {
    case 'BV_DB':
      return '地标'
    case 'BV_HB':
      return '行标'
    case 'BV_GB':
      return '国标'
    case 'BV_GB_PLAN':
      return '计划'
  }
}

let fvue = ''
export function setvue (v) {
  fvue = v
}


function gettext (code) {
  let d = fvue.$options.filters.getHtmlText(code)
  d = d.replace(/[\-－\/\s]/g, '')
  return d

}

export async function checkout (code) {
  var key = fvue.$options.filters.getHtmlText(code)
  var n = { q: key, pageNumber: 1, pageSize: 10, op: '' }
  try {
    var o = jiami('/search/stdJsonPage', n)
    const { data } = await fvue.$http.wlj.post('/api/v1/flfg/zhongansearch', o)
    if (data.Code === 0) {
      let resdata = JSON.parse(jiemi(JSON.parse(data.Data)))
      if (resdata && resdata.rows.length > 0) {
        var key2 = gettext(code)
        return (resdata.rows.find(p => gettext(p.C_STD_CODE) === key2) || { STATE: "未找到" })['STATE']
      }
    }
  } catch (error) { }
  return "未找到"
}
